import React from "react";
import { graphql } from "gatsby";
import { Container, ConvertedMarkdown } from "../components";
import { ServerErrorStyles } from "../styles/PageServerErrorStyles";

const InternalServerError = ({ data }) => {
  const dataQuery = data.allMarkdownRemark.nodes[0],
    componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
    markdownHTML = data.allMarkdownRemark.nodes[0].html,
    markdownID = `server-error-content`;

  return (
    <>
      <Container
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        noSubNav={true}
      >
        <ServerErrorStyles id={`main`} className={`wrapper`}>
          <ConvertedMarkdown mdhtml={markdownHTML} />
        </ServerErrorStyles>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "server-error-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
          definitions
          footnotes
          references
        }
        id
        html
      }
    }
  }
`;

export default InternalServerError;
